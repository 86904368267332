.Header {
  padding: 20px 0;
  border-bottom: 1px solid var(--gray-200);
}

.Header__content--flex {
  display: flex;
  justify-content: space-between;
}

.Header__logo {
  height: 25px;
  width: 25px;
  transition: transform var(--animation-speed-fast)
    var(--animation-accel-fast-to-slow);
}

.Header__logo:active {
  transform: scale(0.9);
}

.Menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Menu__item {
  display: inline-block;
  padding: 0 16px;
  min-width: 44px;
}

.Menu__item:last-child {
  padding-right: 0;
}

.Menu__item .Link--active {
  text-decoration: underline;
  color: var(--primary-color);
}

.Menu__item:first-child {
  padding-left: 0;
}
