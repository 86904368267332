/* MessageBlock Styles */

.MessageBlock {
  padding: 10px 0 0;
  position: relative;
  text-align: center;
}

.MessageBlock--fixed {
  background: var(--white);
  box-shadow: var(--box-shadow-default);
  padding: 32px 40px;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--zindex-message-block);
  width: 100%;
}

@media (min-width: 768px) {
  .MessageBlock--fixed {
    bottom: 20px;
    right: 20px;
    left: unset;
    width: auto;
    border-radius: 4px;
  }
}

.MessageBlock img {
  margin-bottom: 1.3rem;
  max-height: 162px;
  min-height: 120px;
}

.MessageBlock__title {
  color: var(--base-font-color);
  font-size: 24px;
  font-weight: 600;
}

.MessageBlock__copy {
  color: var(--base-font-color);
  max-width: 360px;
  margin: 0 auto 16px;
}

/* Close component */

.Close {
  font-weight: 600;
  padding: 8px;
  position: absolute;
  right: 30px;
  text-align: center;
  top: 20px;
  color: var(--base-font-color-light);
}

.Close:hover {
  color: var(--base-font-color-darker);
}

.Close svg {
  fill: currentColor;
}
