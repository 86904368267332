/* React autosuggest */

.react-autosuggest__parent-container {
  position: relative;
}

.CloseIcon__container .CloseIcon {
  background-color: var(--base-font-color-light);
  border-radius: 50%;
  bottom: 7px;
  color: var(--white);
  font-size: 14px;
  font-weight: 600;
  padding: 4px;
  position: absolute;
  right: 14px;
  text-align: center;
  width: 22px;
}

.CloseIcon__container .CloseIcon:hover {
  background-color: var(--base-font-color-light);
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  -webkit-appearance: none;
  background-color: var(--white);
  border-radius: 100px;
  border: 1px solid transparent;
  color: var(--base-font-color);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 24px;
  padding: 5px 16px;
  width: 100%;
}

.react-autosuggest__input--focused {
  outline: 0;
  outline: none;
  background-color: var(--white);
  box-shadow: 0px 6px 30px -10px rgba(0, 0, 0, 0.75);
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  outline: none;
  background-color: var(--white);
  box-shadow: 0px 12px 50px -10px rgba(0, 0, 0, 0.75);
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 36px;
  width: 100%;
  background-color: var(--white);
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: var(--zindex-layer-2);
  box-shadow: 0px 10px 20px -10px rgba(0, 0, 0, 0.75);
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: var(--gray-100);
}

.react-autosuggest__suggestion .Suggestion__image {
  margin-right: 12px;
  text-align: center;
  width: 1.25em;
}

.react-autosuggest__suggestion .Suggestion__text {
  position: relative;
  top: 1px;
}
