:root {
  --primary-color: #395adb;
  --secondary-color: #fcc72c;
  --danger-color: #ff3535;
  --success-color: #20a94d;
  --info-color: #1552ff;

  /* Grays */
  --white: #fff;
  --gray-100: #f8f9fa;
  --gray-200: #e9ecef;
  --gray-300: #dee2e6;
  --gray-400: #ced4da;
  --gray-500: #adb5bd;
  --gray-600: #6c757d;
  --gray-700: #495057;
  --gray-800: #343a40;
  --gray-900: #212529;
  --black: #000;

  /* TYPOGRAPHY */
  --base-font-color-dark: #2b2b2b;
  --base-font-color: #222;
  --base-font-color-light: #555;
  --base-font-color-lighter: #888;

  /* ANIMATIONS: SPEED */
  --animation-speed-fast: 0.2s;
  --animation-speed-medium: 0.3s;
  --animation-speed-slow: 0.4s;

  /* ANIMATIONS: FUNCTIONS */
  --animation-accel-fast-to-slow: ease-out; /* slow to hault, move fast to slow, ideal for entrances */
  --animation-accel-slow-to-fast: ease-in; /* accelerate slowly, move slow to fast, ideal for exits */

  /* DARK MODE */

  --dm-font-color: #fff;
  --dm-bg-primary: #1c1f2a;
  --dm-bg-secondary: #282b35;

  /* OTHER GLOBALS */
  --box-shadow-default: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  --line-height-default: 1.6;
  --line-height-medium: 1.8;
  --border-radius-default: 4px;
  --outline-default: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);

  /* Z-INDEX */
  --zindex-tooltip: 100;

  --zindex-message-block: 300;

  --zindex-layer-1: 1;
  --zindex-layer-2: 5;
  --zindex-layer-3: 10;

  --zindex-behind: -1;
}
