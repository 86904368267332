.ProjectItem__wrapper {
  background-color: var(--white);
  border-radius: 24px;
  box-shadow: var(--box-shadow-default);
  position: relative;
  margin-bottom: 24px;
}

.ProjectItem__wrapper a {
  border-radius: 24px;
}

.ProjectItem__img-wrapper {
  border-radius: 24px 24px 0 0;
  overflow: hidden;
  background-color: var(--grey-200);
  position: relative;
  transition: transform var(--animation-speed-medium)
    var(--animation-accel-fast-to-slow);
}

.ProjectItem__img-wrapper:hover {
  transform: scale(1.02);
}

.ProjectItem__img-wrapper:hover .ProjectItem__category {
  opacity: 1;
}

.ProjectItem__img-wrapper [class^='css-'] {
  border-radius: 0;
  line-height: unset !important;
}

.ProjectItem__content {
  border-radius: 0 0 24px 24px;
  padding: 20px;
}

.ProjectItem__category {
  opacity: 1;
  background-color: var(--white);
  border-radius: 30px;
  bottom: 12px;
  box-shadow: var(--box-shadow-default);
  color: var(--base-font-color);
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 3.5px;
  padding: 10px 20px;
  position: absolute;
  right: 12px;
  text-transform: uppercase;
  transition: opacity var(--animation-speed-medium)
    var(--animation-accel-fast-to-slow);
}

@media (min-width: 992px) {
  .ProjectItem__category {
    opacity: 0;
  }
}

.ProjectItem__category.ProjectItem__category--blue {
  background-color: var(--primary-color);
  color: var(--white);
}

.ProjectItem__category.ProjectItem__category--green {
  background-color: var(--success-color);
  color: var(--white);
}

.ProjectItem__title {
  color: var(--base-font-color);
  font-size: 20px;
  font-weight: 600;
}

.ProjectItem__copy {
  color: var(--base-font-color);
  letter-spacing: 0.4px;
}
