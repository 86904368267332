.Profile__img-wrapper {
  position: relative;
  max-width: 368px;
  max-height: 372px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .Profile__img-wrapper {
    max-height: auto;
  }
}

.Profile__img {
  max-width: 100%;
  min-width: 300px;
}

.Profile__img-wrapper [class^='css-'] {
  max-width: 100%;
  min-width: 300px;
}

.Profile__block .App__section-title {
  font-size: 24px;
  margin-bottom: 12px;
}

.Profile__block .App__section-copy {
  font-size: 17px;
  line-height: var(--line-height-medium);
  margin-bottom: 18px;
}

.Profile__summary {
  margin-bottom: 24px;
}
