.Error404__wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60vh;
  padding: 60px 0;
}

.Error404__img {
  margin-bottom: 1rem;
}

.Error404__title {
  font-size: 20px;
  color: var(--base-font-color);
  letter-spacing: 1.5px;
}

.Error404__copy {
  color: var(--base-font-color);
}
