.Footer {
  background-color: var(--dm-bg-secondary);
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: var(--white);
}

.Footer__logo {
  animation: App__logo-spin infinite 20s linear;
  height: 30vmin;
}

@keyframes App__logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Footer__links {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.Footer__link-item {
  padding: 0 6px;
  display: inline-block;
}
