/* Global Styles */

::selection {
  background: var(--secondary-color); /* WebKit/Blink Browsers */
  color: var(--white);
}

::-moz-selection {
  background: var(--secondary-color); /* Gecko Browsers */
  color: var(--white);
}

hr {
  border-bottom: 1px solid var(--gray-200) !important;
  margin: 2rem 0;
}

/* Utils */

.Util__clearfix {
  overflow: auto;
}

.Util__text-center {
  text-align: center !important;
}

.Util__text-left {
  text-align: left !important;
}

.Util__text-right {
  text-align: right !important;
}

.Util__hidden {
  display: none !important;
}

.Util__hide-for-sm {
  display: none;
}

@media (min-width: 768px) {
  .Util__hide-for-sm {
    display: block;
  }

  .Util__hide-for-md {
    display: none;
  }
}

/* App styles */

/* Typography */

.App__section-title {
  color: var(--base-font-color);
  font-weight: 600;
  font-size: 36px;
}

.App__section-subheader {
  color: var(--base-font-color);
  font-size: 22px;
  line-height: 40px;
  margin: 0;
}

@media (min-width: 1200px) {
  .App__section-subheader {
    max-width: 57%;
  }
}

.App__section-copy {
  color: var(--base-font-color);
  font-size: 17px;
}

.App__highlight {
  font-weight: 600;
  color: var(--primary-color);
}

.App__swish-highlight {
  background-image: linear-gradient(120deg, #feeebf, var(--secondary-color));
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 0.1em;
}

/* Layout Elements */

/* Typical use case:
****
.App__view
  .App__content-block
    .App__container */

.App__view {
  padding: 0;
}

.App__content-block--100 {
  margin: 100px 0;
}

.App__content-block--50 {
  margin: 50px 0;
}

.App__container {
  margin: 0 auto;
  max-width: 1370px;
  padding-left: 16px;
  padding-right: 16px;
}

@media (min-width: 768px) {
  .App__container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media (min-width: 992px) {
  .App__container {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.App__container--narrow {
  max-width: 1075px;
}

/* Flex container/column */

.App__container--flex {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .App__container--flex {
    flex-direction: row;
  }
}

.App__column {
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .App__column {
    margin-bottom: 0;
    padding: 0 15px;
  }
}

/* Simple block grid */

/*
SP - 1 column
Tablet - 2 column
PC - 3 column
*/

.App__block-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.App__block-grid-item {
  margin-bottom: 24px;
}

@media (min-width: 768px) {
  .App__block-grid-item {
    margin-right: 24px;
    width: calc(50% - 12px);
  }

  .App__block-grid-item:nth-child(2n) {
    margin-right: 0;
  }
}

@media (min-width: 1200px) {
  .App__block-grid-item {
    width: calc(33.33% - 16px);
  }

  .App__block-grid-item:nth-child(2n) {
    margin-right: 24px;
  }

  .App__block-grid-item:nth-child(3n) {
    margin-right: 0;
  }
}

/* App Elements */

.Button {
  border-radius: 0.25rem;
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 10px 40px;
  text-align: center;
  transition: color 150ms ease-in-out, background-color 150ms ease-in-out,
    border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  min-width: 186px;
}

.Button--primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white);
}

.Button:focus {
  outline: 0;
  box-shadow: var(--outline-default);
}

.Button--primary:hover,
.Button--primary:focus {
  background-color: #274ad4;
  border-color: #2342be;
  color: var(--white);
}

.Button--success {
  background-color: var(--success-color);
  border-color: var(--success-color);
  color: var(--white);
}

.Button--success:hover,
.Button--success:focus {
  background-color: #1c9443;
  border-color: #1a8b3f;
  color: var(--white);
}

.Button--rounded {
  border-radius: 10rem !important;
}

/* Icons */

.Icon--right {
  height: 16px;
  position: relative;
  top: -2px;
}

/* Forms */

.App__input {
  -webkit-appearance: none;
  background-color: var(--white);
  border-radius: 100px;
  border: 1px solid transparent;
  color: var(--base-font-color);
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.3px;
  line-height: 24px;
  padding: 5px 16px;
  width: 100%;
}

.App__input:focus {
  outline: 0;
  box-shadow: 0 2px 2px rgba(66, 165, 245, 0.24),
    0 0 2px rgba(66, 165, 245, 0.12);
  border: 1px solid #42a5f5;
}

/* Images */

.App__responsive-media {
  border-radius: var(--border-radius-default);
}

.App__responsive-media iframe,
.App__responsive-media image {
  max-width: 100%;
}

.App__styled-media {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.18);
  max-height: 350px;
  transition: transform var(--animation-speed-fast)
      var(--animation-accel-fast-to-slow),
    opacity var(--animation-speed-fast) var(--animation-accel-fast-to-slow);
  opacity: 1;
}

.App__styled-media:hover {
  transform: scale(1.05);
  opacity: 0.95;
}

/* Dark mode styles */

.Darkmode__button-wrapper .Button {
  min-width: auto;
  height: 27px;
  padding: 5px;
  overflow: hidden;
  background-color: transparent;
  border-color: transparent;
}

.Darkmode__button-wrapper .Button:focus {
  outline: -webkit-focus-ring-color auto 1px;
  box-shadow: none;
}

/* Update bg colors */

body.dark,
body.dark .Company__block {
  background-color: var(--dm-bg-primary);
  color: var(--dm-font-color);
}

body.dark .ProjectItem__content,
body.dark .ProjectItem__wrapper,
body.dark .ProjectDetail__hero {
  background-color: var(--dm-bg-secondary) !important;
}

/* Update font colors */

body.dark .App__section-subheader,
body.dark .ProjectItem__title,
body.dark .ProjectItem__copy,
body.dark .App__section-title,
body.dark .App__section-copy,
body.dark .Promotion__copy,
body.dark .MessageBlock__title,
body.dark .MessageBlock__copy,
body.dark .ResultsList__container,
body.dark .MessageBlock--fixed .Close,
body.dark .ProjectDetail__content dl dd,
body.dark .Error404__title,
body.dark .Error404__copy {
  color: var(--dm-font-color);
}

/* Other darkmode changes */

body.dark .Company__block {
  border-bottom: 1px solid var(--dm-font-color);
  border-top: 1px solid var(--dm-font-color);
}

body.dark .Header {
  border-bottom: 1px solid var(--dm-font-color);
}

body.dark .Skills__hero,
body.dark .MessageBlock--fixed {
  background-image: none;
  background-color: var(--dm-bg-secondary);
}

body.dark .react-autosuggest__input,
body.dark .react-autosuggest__suggestions-list {
  color: var(--dm-font-color);
  background-color: var(--dm-bg-primary);
}

body.dark .react-autosuggest__suggestion--highlighted {
  background-color: var(--gray-800);
}

body.dark .Company__logo {
  filter: grayscale(100%);
}
