/* Company section */

.Company__block {
  background-color: var(--gray-200);
  padding: 100px 0;
  text-align: center;
}

@media (min-width: 768px) {
  .Company__block {
    padding: 150px 0;
  }
}

.Company__block .App__section-title {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 18px;
}

.Company__block .App__section-copy {
  color: var(--base-font-color);
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .Company__block .App__section-copy {
    margin-bottom: 30px;
  }
}

.Company__logo-wrapper {
  padding: 20px;
  text-align: center;
}

.Company__logo {
  max-height: 26px;
  width: 9rem;
}

.Company__block-grid {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 18px 0;
}

.Company__block-grid-item {
  width: 50%;
}

@media (min-width: 768px) {
  .Company__block-grid {
    flex-direction: row;
  }

  .Company__block-grid-item {
    width: 33.3333%;
  }
}

@media (min-width: 1200px) {
  .Company__block-grid {
    justify-content: space-around;
  }

  .Company__block-grid-item {
    width: auto;
  }
}

/* Promotion section */

.Promotion__block {
  padding: 0;
}

@media (min-width: 768px) {
  .Promotion__block {
    padding: 60px 0 0;
  }
}

.Promotion__block .App__container--flex {
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
}

.Promotion__block .App__container--flex .App__column--left {
  width: 100%;
}

@media (min-width: 768px) {
  .Promotion__block .App__container--flex .App__column--left {
    width: 40%;
  }
}

@media (min-width: 768px) {
  .Promotion__block .App__container--flex {
    flex-direction: row;
  }
}

.Promotion__block .App__container--flex .App__column--left {
  margin-bottom: 32px;
  text-align: center;
}

@media (min-width: 768px) {
  .Promotion__block .App__container--flex .App__column--left {
    text-align: left;
    margin-bottom: 0;
  }
}

.Promotion__category {
  color: var(--info-color);
  font-weight: 600;
  margin-bottom: 0;
}

.Promotion__copy {
  color: var(--base-font-color);
  font-size: 17px;
  margin: 0 auto 24px;
  max-width: 330px;
}

@media (min-width: 768px) {
  .Promotion__copy {
    max-width: 82%;
    margin: 0 0 24px;
  }
}

.Promotion__block .App__section-title {
  font-weight: 400;
}
