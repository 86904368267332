/* ProjectDetail Styles */

.ProjectDetail__hero {
  background-color: var(--primary-color);
  color: var(--white);
  min-height: 400px;
  padding: 62px 0 0;
  text-align: center;
}

@media (min-width: 992px) {
  .ProjectDetail__hero {
    min-height: 412px;
  }
}

.Hero__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media (min-width: 768px) {
  .Hero__content {
    flex-direction: row;
  }
}

.Hero__category {
  color: var(--secondary-color);
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  text-transform: uppercase;
}

.Hero__title {
  font-size: 40px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 2rem;
}

.Hero__img {
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.18);
  max-height: 350px;
}

.ProjectDetail__content {
  min-height: 60vh;
}

.ProjectDetail__content .App__section-copy {
  line-height: var(--line-height-medium);
}

.ProjectDetail__content dl dt {
  color: var(--primary-color);
  font-weight: 600;
  letter-spacing: 1.4px;
}

.ProjectDetail__content dl dt,
.ProjectDetail__content dl dd {
  margin-bottom: 16px;
}

.ProjectDetail__action {
  padding: 24px 0;
  text-align: center;
  margin: 0;
}

.ProjectDetail__contributions {
  margin-bottom: 60px;
}
