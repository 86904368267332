.WaveAnimation__container {
  position: relative;
  bottom: -10px;
  color: var(--dm-bg-secondary);
  padding-top: 40px;
}

.WaveAnimation__container svg {
  fill: currentColor;
}
